import React from "react"
import * as styles from "./office-hours.module.scss"
import { useGeneralSettings } from "hooks/useGeneralSettings"

export const OfficeHours = ({ slug }) => {
  const { formattedOffices } = useGeneralSettings()
  const mainOffice = formattedOffices[0]
  const simpleOfficeHours = mainOffice?.simpleOfficeHours
  const simpleServiceHours = mainOffice?.simpleServiceHours

  if (
    (simpleOfficeHours || simpleServiceHours) &&
    slug === "contact-us"
  )
    return (
      <div className={styles.officeHours}>
        {simpleOfficeHours && (
          <span>
            <strong>Office Hours: </strong> {simpleOfficeHours}
          </span>
        )}
        {simpleServiceHours && (
          <span>
            <strong>Service Hours: </strong> {simpleServiceHours}
          </span>
        )}
        <hr className={`container ${styles.sectionDivider}`} />
      </div>
    )
  else return <></>
}

export default OfficeHours
