import React, { useContext } from "react"
import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { AppContext } from "../context/context"
import { FaPhoneAlt } from "react-icons/fa"
import { useMainOfficeNumber } from "hooks/useMainOfficeNumber"
import { useLayout } from "contexts/components/LayoutContext"
import * as styles from "./form-stub.module.scss"

const FormStub = ({ content }) => {
  const { breadcrumbLocation } = useLayout()
  const currentPage = breadcrumbLocation.startsWith("/")
    ? breadcrumbLocation
    : `/${breadcrumbLocation}`

  const { mainOfficeNumber } = useMainOfficeNumber({})
  const trackingPhone = mainOfficeNumber || "(207) 888-8888"

  const { setEmailAddress, setName, size } = useContext(AppContext)
  const { button, fields, heading, icon, sub_heading } = content

  const setFields = e => {
    const { name, value } = e.target
    switch (name) {
      case "Full Name":
        return setName(value)
      case "Email":
        return setEmailAddress(value)
      default:
        break
    }
  }

  const getTablet = () => {
    if (size >= 1024) {
      return (
        <a href={`tel: ${trackingPhone}`} className={styles.callButton}>
          {trackingPhone}
          <div className={styles.phone}>
            <FaPhoneAlt />
          </div>
        </a>
      )
    } else {
      return ""
    }
  }
  const getLaptop = () => {
    if (size >= 1366) {
      return (
        <div className={styles.fieldContainer}>
          {fields.map(field => {
            const { label, form_field, form_field_type, id } = field
            return (
              <div key={id} className={styles.field}>
                <label htmlFor={form_field}>{label}</label>
                <input
                  type={form_field_type}
                  name={form_field}
                  placeholder={
                    form_field_type === "Email"
                      ? "email@example.com"
                      : "John Smith"
                  }
                  onBlur={setFields}
                />
              </div>
            )
          })}
        </div>
      )
    } else {
      return ""
    }
  }
  return (
    <section className={styles.bannerCta}>
      <div className={`container ${styles.ctaContainer}`}>
        <div className={styles.ctaHook}>
          {(icon?.localFile?.publicURL || icon?.url) && (
            <img
              src={icon?.localFile?.publicURL || icon?.url}
              alt={icon.alternativeText ?? ""}
            />
          )}
          <div className={styles.freeQuote}>
            <h3 className="uppercase">{heading}</h3>
            <p>{sub_heading}</p>
          </div>
        </div>
        <div className={styles.ctaFormContainer}>
          {getLaptop()}
          {button && (
            <AnchorLink
              to={`${currentPage}#form-banner`}
              className="primary-button"
            >
              {button.button_text}
            </AnchorLink>
          )}
        </div>
        {getTablet()}
      </div>
    </section>
  )
}

export default FormStub

export const strapiQuery = graphql`
  fragment StrapiFormStubBannerFragment on STRAPI_GLOBAL_COMPONENT {
    id
    global_form_stub_banner {
      id
      heading
      sub_heading
      button {
        ...StrapiButtonFragment
      }
      form_fields {
        id
        custom_place_holder
        form_field
        form_field_type
        hidden_field_value
        label
        options
        required
      }
      icon {
        ...StrapiImageFragment
      }
    }
  }
`
