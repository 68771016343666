import React from "react"
import { graphql, PageProps } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import Layout from "components/layout"
import SEO from "components/seo/seo"
import ContentBlocks from "components/content-blocks/ContentBlocks"
import FormStub from "components/form-stub/FormStub"
import GlobalFooter from "components/global-footer/GlobalFooter"
import GoogleRating from "components/google-rating-banner/GoogleRating"
import Hero from "components/hero/Hero"
import OfficeHours from "components/office-hours/OfficeHours"
import SimpleHero from "components/simple-hero/SimpleHero"
import { SimpleTileList } from "components/simple-tile/SimpleTileList"
import { EMPTY_PAGE } from "assets/js/EmptyPage"

interface GenericInteriorProps extends PageProps {
  pageContext: {
    slug: string
    contentUrlPathCodex: Record<string, string>
    linkedPagesUrlPathCodex: Record<string, string>
  }
  data: any
}

const GenericInterior: React.FC<GenericInteriorProps> = ({
  data,
  location,
  pageContext,
}) => {
  const { pathname, hash } = location
  const { contentUrlPathCodex, slug, linkedPagesUrlPathCodex } = pageContext
  const [genericPage] = data.allStrapiPage.nodes
  const { globalFormStubBanner, customCTAText } = useGeneralSettings()
  const { googleReviewsUrl } = useIntegrationsSettings()

  const {
    content,
    hero,
    meta_data,
    linked_pages,
    global_footer,
    noindex_this_page,
    is_pest_library_parent_page,
    is_gallery_parent_page,
    disable_form_stub,
    custom_tracking_number,
  } = genericPage ?? EMPTY_PAGE

  const isPestLibrary = is_pest_library_parent_page
  const isGallery = is_gallery_parent_page

  const noIndexPages = [
    "accessibility-statement",
    "terms-and-conditions",
    "privacy-policy",
    "terms-of-use",
    "terms-of-service",
  ]

  const noIndex =
    noIndexPages.some(page => page.includes(slug)) || noindex_this_page

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      customTrackingNumber={custom_tracking_number}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      location={slug}
      pathName={pathname}
    >
      <SEO
        canonical={meta_data?.canonical_link}
        title={meta_data?.meta_title || ""}
        description={meta_data?.meta_description || undefined}
        image={meta_data?.rich_data_image}
        noIndex={noIndex}
      />

      {hero?.hero_buttons?.length > 0 ? (
        <Hero banner={hero} customCTA={customCTAText} />
      ) : (
        <SimpleHero banner={hero} />
      )}

      {!disable_form_stub &&
        globalFormStubBanner &&
        Object.keys(globalFormStubBanner).length > 0 && (
          <FormStub content={globalFormStubBanner} />
        )}

      {googleReviewsUrl && <GoogleRating url={googleReviewsUrl} />}

      <OfficeHours slug={slug} />

      {content?.length > 0 && <ContentBlocks strapiContent={content} />}

      {(isPestLibrary || isGallery) && linked_pages.length > 0 && (
        <SimpleTileList tiles={linked_pages} />
      )}

      {global_footer?.content?.length > 0 && (
        <GlobalFooter content={global_footer.content} />
      )}
    </Layout>
  )
}

export const getPage = graphql`
  query ($slug: String!) {
    allStrapiPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        noindex_this_page
        is_pest_library_parent_page
        is_gallery_parent_page
        is_service_page
        alternating_content
        custom_tracking_number
        disable_form_stub
        meta_data {
          canonical_link
          meta_title
          meta_description
          rich_data_link
          rich_data_image {
            localFile {
              publicURL
            }
          }
        }
        hero {
          ...StrapiHeroBannerFragment
        }
        content {
          ...StrapiContentBlockFragment
          ...StrapiCTABannerFragment
          ...StrapiFaqGroupBannerFragment
          ...StrapiFormFragment
          ...StrapiHtmlEmbedFragment
          ...StrapiLogoSliderFragment
          ...StrapiMultiContentBlocksFragment
          ...StrapiGalleryFragment
          ...StrapiNavigationTilesFragment
          ...StrapiServiceAreaGroupFragment
          ...StrapiServiceComparisonFragment
          ...StrapiServiceFragment
          ...StrapiLegacyPestLibraryFragment
        }
        linked_pages {
          id
          title
          slug
          meta_data {
            rich_data_image {
              ...StrapiImageFragment
            }
            overlay_cta_text
          }
        }
        global_footer {
          ...StrapiGlobalFooterFragment
        }
      }
    }
  }
`

export default GenericInterior
